.warning-paper{
    background-color: var(--secondary-light) !important;
    color: var(--white) !important;
    margin-bottom: 0;
}

@-webkit-keyframes blackWhiteFade {  
    0% { background-color: var(--secondary-light) !important; }
    50% { background-color: black; }
    100% { background-color: var(--secondary-light) !important; }
  }