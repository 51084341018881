.operationNode {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  color: white;
  font-weight: bold;
  text-align: center;
  white-space-collapse : break-spaces;
}

