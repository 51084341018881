.edgebutton {
    width: 1px;
    height:1px;
    background: #eee;
    border: 1px solid #fff;
    cursor: pointer;
    border-radius: 50%;
    font-size: 1px;
    line-height: 1;
    text-align: center;

  }
  
  .edgebutton:hover {
    box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.08);
    width: 20px;
    height:20px;
    font-size: 20px;
  }
  