.commentNode {
	position: relative;
	background-color: rgb(231, 164, 20);
 	margin:5px; 
	 border-radius: 5px;
	 -webkit-border-radius: 5px;
	 -moz-border-radius: 5px;
}



.tri-right.border.btm-left:before {
	content: ' ';
	position: absolute;
	width: 0;
	height: 0;
	left: -8px;
	right: auto;
	top: auto;
	bottom: -40px;
	border: 32px solid;
	border-color: transparent transparent transparent #666;
}

.tri-right.btm-left:after {
	content: ' ';
	position: absolute;
	width: 0;
	height: 0;
	left: 0px;
	right: auto;
	top: auto;
	bottom: -20px;
	border: 22px solid;
	border-color: transparent transparent transparent rgb(231, 164, 20);
}