.decisionNode {
  background:
   linear-gradient(to top right   ,rgb(255, 0, 34) 49.5%, transparent 50%) top right,
   linear-gradient(to top left    ,rgb(255, 0, 34) 49.5%, transparent 50%) top left,
   linear-gradient(to bottom right,rgb(255, 0, 34) 49.5%, transparent 50%) bottom right,
   linear-gradient(to bottom left ,rgb(255, 0, 34) 49.5%, transparent 50%) bottom left;
  background-size:50% 50%;
  background-repeat:no-repeat;
  margin:5px;
}


.decisionNode:before {
  content:"";
  display:block;
  padding-top:57.73%;
}