

  .buttonNode {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    color: white; 
    font-weight: bold;
    text-align: center;
  }
  
  .buttonNode:hover {
    transform: scale(1.1); 
}