
.arrow.hover-enabled span:hover {
  transform: translate(-50%, -50%) rotate(45deg) scaleY(-1.5) scaleX(1.5);
}

.arrow2.hover-enabled span:hover{
  transform: translate(-50%, -50%) rotate(45deg) scaleZ(-1.5) scaleY(-1.5) scaleX(-1.5);
}


.arrow,
.arrow2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.arrow {
  width: 100px;
  height: 100px;
  transform: rotate(45deg) scaleY(-1);
}


.arrow2 {
  margin-left: 55px;
  width: 100px;
  height: 100px;
  transform: rotate(135deg);
  z-index: 1;
}

.circle {
  position: absolute;
  box-sizing: border-box;
  height: 150px;
  width: 150px;
  border: 15px solid #000;
  border-radius: 100%;

  -webkit-clip-path: inset(0 55% 0 0);
  clip-path: inset(0 62% 0% 0);
}

.triangle {
  position: absolute;
  width: 45px;
  height: 40px;
  margin-top: -6px;
  margin-left: 50px;
  -webkit-clip-path: polygon(50% 0, 0% 100%, 100% 100%);
  clip-path: polygon(50% 0, 0% 100%, 100% 100%);
  -moz-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.triangle2 {
  position: absolute;
  width: 55px;
  height: 50px;
  background-color: white;

  margin-top: -11px;
  margin-left: 50px;
  -webkit-clip-path: polygon(50% 0, 0% 100%, 100% 100%);
  clip-path: polygon(50% 0, 0% 100%, 100% 100%);

  -moz-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}


.arrow span {
position: absolute;
width: 100%;
  margin-bottom: 20px;
text-align: center;
top: 80%;
left: 60%;
transform: translate(-50%, -50%) rotate(45deg) scaleY(-1);
z-index: 3;
 font-family: 'Anton', sans-serif;
font-size: 20px;
font-weight: 100!important;
cursor: default;
}

.arrow2 span {
position: absolute;
width: 100%;
  margin-bottom: 20px;
text-align: center;
top: 80%;
left: 60%;
transform: translate(-50%, -50%) rotate(45deg) scaleZ(-1) scaleY(-1) scaleX(-1);
z-index: 3;
 font-family: 'Anton', sans-serif;
font-size: 20px;
font-weight: 100!important;
cursor: default;
}
