.nodebutton {
  width: 1px;
  height: 1px;
  background: #eee;
  border: 1px solid #fff;
  cursor: pointer;
  border-radius: 50%;
  font-size: 1px;
  line-height: 1;
  text-align: center;

}

.nodebutton:hover {
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.08);
  width: 15px;
  height: 15px;
  font-size: 15px;
}

.textnode {
  position: absolute;
  white-space: nowrap;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  font-size: 12px;
}


.sipocnode {
  width: 150px;
  height: 40px;
  padding: 10px;
  border: 1px solid;
  border-radius: 5px;
  background-color: #fff
}