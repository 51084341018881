

  .sipoctitle {
    position: absolute;
    white-space: nowrap;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    font-size: 20px;
    color: white;
    text-transform: uppercase;
  } 
  .providertitle {
    position: absolute;
    white-space: nowrap;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    font-size: 15px;
    text-transform: uppercase;
  }
  
  .nodegometry {
    width: 150px; 
    height: 40px; 
    padding: 5px; 
    border : transparent
  }