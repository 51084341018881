:root {
  --small: 10px;
  --medium: 15px;
  --large: 20px;


  --primary: hsl(201, 59%, 18%);
  --primary-light: #1d5472;
  --filter-header: #cee6f2;
  --primary-dark: #081820;
  --error: #FFCCCC;
  --alert: #d32f2f;
  --disabled : #ededed;
  --disabled-transparent : rgba(96, 91, 91, 0.12);
  --white : #fff;
  --dark: #000; 
  --selected-row: #4c6476;
  --favorite:  #7E99AC;
  --warning: #EDBD01;
  --brand-green:  #509153;



}

*:focus-visible {
  border: none;
  outline: none;
}

/* body {
  margin: 0;
} */

.main-container {
  min-height: calc(100vh - 65px);
  height: calc(100% - 65px);
  padding: 20px;
  background-color: var(--white);
  text-align: center;
  overflow: hidden;
}

.padding-bottom-small {
  padding-bottom: var(--small);
}

.padding-bottom-medium {
  padding-bottom: var(--medium);
}

.padding-bottom-large {
  padding-bottom: var(--large);
}

.margin-bottom-small {
  margin-bottom: var(--small);
}

.margin-bottom-medium {
  margin-bottom: var(--medium);
}

.margin-bottom-large {
  margin-bottom: var(--large);
}

.margin-top-small {
  margin-top: var(--small);
}

.margin-top-medium {
  margin-top: var(--medium);
}

.margin-top-large {
  margin-top: var(--large);
}

.margin-small{
  margin: var(--small);
}

.margin-medium{
  margin: var(--medium);
}

.padding-small {
  padding: var(--small);
}

.padding-medium{
  padding: var(--medium);
}

.padding-large{
  padding: var(--large);
}

.uppercase{
  text-transform: uppercase;
}

.capitalize-first-letter::first-letter{
  text-transform: capitalize;
}

.paper-form{
  padding: var(--medium);
}

.typography-color{
  color: var(--primary);
} 

.typography-title{
  color: var(--primary);
  font-weight: bold !important;
  letter-spacing: 2px !important;
  font-size: 40px !important;
  text-transform: uppercase;
  /* font-family: 'Anton', sans-serif; */
}

.typography-project-prefix{
  color: var(--primary);
  text-transform: capitalize;
  font-family: 'Anton', sans-serif;
  font-weight: 100!important;
}

.typography-project{
  color: var(--primary);
  font-weight: bold !important;
  text-transform: capitalize;
  font-family: 'Anton', sans-serif;
}

.hidden {
  display: none;
}

.mini-button {
  line-height: 1 !important;
  font-size: 0.75rem !important;
  padding: 4px 10px !important;
}

.icon-button-navigation {
  border: 3px solid var(--primary) !important
}

.text-overflow-ellipsis{
  overflow: hidden;
  text-overflow: ellipsis;
}

.no-wrap{
  white-space: nowrap;
}

.mini-button {
  line-height: 1 !important;
  font-size: 0.75rem !important;
  padding: 4px 10px !important;
}
